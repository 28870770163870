import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Tom Monk</h1>
        <p>Tom Monk has been building successful software solutions for over twenty years. Contact Tom on&#160;
        <a
          className="App-link"
          href="https://www.linkedin.com/in/tommonk/"
          rel="noopener"
        >
          LinkedIn
        </a>
        &#160;or&#160;
        <a
          className="App-link"
          href="https://github.com/tm0nk"
          rel="noopener"
        >
          GitHub
        </a>
        .
        </p>
      </header>
    </div>
  );
}

export default App;
